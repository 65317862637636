import { Title } from '@mantine/core'
import useAxios from 'axios-hooks'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { IForm, ITag } from '../types'
import { notifications } from '@mantine/notifications'
import { NewTagForm } from './NewTagForm'
import { Response } from '../../../types/response'

export type TNewTagProps = {
  navigateToViewAfterSuccess?: boolean
  // eslint-disable-next-line no-unused-vars
  onSuccessCallback?: (data: ITag) => void
}

export const NewTag = ({
  navigateToViewAfterSuccess,
  onSuccessCallback,
}: TNewTagProps) => {
  const navigate = useNavigate()
  const [{}, executePost] = useAxios<Response<ITag>>(
    {
      url: '/tag/',
      method: 'POST',
    },
    {
      manual: true,
    }
  )
  return (
    <>
      <Title order={2}>New Tag</Title>
      <Formik<IForm>
        initialValues={{
          name: '',
          description: undefined,
          color: '#1971c2',
        }}
        onSubmit={async (values) => {
          try {
            const response = await executePost({ data: values })
            navigateToViewAfterSuccess === true &&
              navigate(`/tag/${response.data.data.uuid}`)
            notifications.show({
              title: 'Tag saved',
              message: 'Your tag was successfully saved',
            })
            onSuccessCallback && onSuccessCallback(response.data.data)
          } catch {
            notifications.show({
              title: 'Something went wrong',
              message:
                'There was some error that prevented us from saving new tag',
            })
          }
        }}
      >
        <NewTagForm />
      </Formik>
    </>
  )
}
