import { Badge as MantineBadge } from '@mantine/core'
import Color from 'color'
import { useContext } from 'react'
import { ColorSchemeContext, TContextColorScheme } from '../App'

export type TBadgeProps = {
  children: React.ReactNode
  color: string
}

export const Badge = (props: TBadgeProps) => {
  const { colorScheme } = useContext<TContextColorScheme>(ColorSchemeContext)
  return (
    <MantineBadge
      sx={{
        backgroundColor:
          colorScheme === 'light'
            ? Color(props.color).lighten(0.4).alpha(0.3).hexa()
            : Color(props.color).hexa(),
        color:
          colorScheme === 'light'
            ? Color(props.color).darken(0.4).hex()
            : Color(props.color).isLight()
            ? '#000'
            : '#fff',
        border: '1px solid ' + props.color,
      }}
    >
      {props.children}
    </MantineBadge>
  )
}
