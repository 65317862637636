import { ActionIcon } from '@mantine/core'
import { useContext } from 'react'
import { ColorSchemeContext, TContextColorScheme } from '../App'
import { FaSun, FaMoon } from 'react-icons/fa'

export const ThemeIcon = () => {
  const { colorScheme, toggleColorScheme } =
    useContext<TContextColorScheme>(ColorSchemeContext)
  return (
    <ActionIcon
      variant='outline'
      color={colorScheme ? 'yellow' : 'blue'}
      onClick={() => toggleColorScheme && toggleColorScheme()}
      title='Toggle color scheme'
    >
      {colorScheme === 'dark' ? (
        <FaSun size='1.1rem' />
      ) : (
        <FaMoon size='1.1rem' />
      )}
    </ActionIcon>
  )
}
