import { Table } from '@mantine/core'
import { Response } from '../../../types/response'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { IPostVerionList } from '../types'
import { LoginForm } from '../../authentication/login/LoginForm'
import { useAxiosRefetching } from '../../../hooks/useAxiosRefetching'

export const ListPostVersions = () => {
  const navigate = useNavigate()
  const { uuid } = useParams()
  const [{ data: versions, error }] = useAxiosRefetching<
    Response<IPostVerionList[]>
  >(`/post/version/${uuid}/all`)
  if (error?.response?.status === 401) {
    return <LoginForm customLoginText='Log in to access versioning' />
  }

  const rows = versions?.data.map((version) => (
    <tr
      style={{ cursor: 'pointer' }}
      key={version.uuid}
      onClick={() => {
        version.uuid === ''
          ? navigate(`/post/${uuid}`)
          : navigate(`/post/${uuid}/${version.number}`)
      }}
    >
      <td>{version.number}</td>
      <td>{moment(version.created_dt).format('YYYY-MM-DD HH:mm')}</td>
    </tr>
  ))
  return (
    <Table>
      <thead>
        <tr>
          <th>Number</th>
          <th>Created at</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  )
}
