import axios, { AxiosError } from 'axios'
import { FormikHelpers } from 'formik'
import { Response } from '../types/response'

export const handleValidationErrors = (
  err: Error | AxiosError,
  setErrors: FormikHelpers<unknown>['setErrors']
) => {
  if (axios.isAxiosError(err) && err.response?.status === 400) {
    setErrors(
      (err as AxiosError<Response<Record<string, never>>>).response?.data.meta
        .validation || {}
    )
  }
}
