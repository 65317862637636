import { IPost } from '../types'
import { Response } from '../../../types/response'
import { Grid, Title } from '@mantine/core'
import { CardStack } from './CardStack'
import { useAxiosRefetching } from '../../../hooks/useAxiosRefetching'

export const ShowLastPosts = () => {
  const [{ data: dataCreated }] = useAxiosRefetching<Response<IPost[]>>(
    '/post/latest/created'
  )
  const [{ data: dataUpdated }] = useAxiosRefetching<Response<IPost[]>>(
    '/post/latest/updated'
  )
  return (
    <>
      <Grid>
        <Grid.Col span={6}>
          <Title pb='lg'>Recently created</Title>
          {dataCreated && <CardStack posts={dataCreated.data} />}
        </Grid.Col>
        <Grid.Col span={6}>
          <Title pb='lg'>Recently updated</Title>
          {dataUpdated && <CardStack posts={dataUpdated.data} />}
        </Grid.Col>
      </Grid>
    </>
  )
}
