import { Button, Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { RegisterForm } from './RegisterForm'

export const RegisterModal = () => {
  const [opened, { open, close }] = useDisclosure(false)

  const closeModal = () => {
    close()
  }
  return (
    <>
      <Button
        variant='subtle'
        onClick={() => {
          open()
        }}
      >
        Register
      </Button>
      <Modal opened={opened} onClose={closeModal}>
        <RegisterForm onRegister={closeModal} />
      </Modal>
    </>
  )
}
