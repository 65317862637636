import { Button, Stack, Text, Title } from '@mantine/core'
import TextInput from '../../../form-components/TextInput'
import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { ILoggedInUser, LoggedInUserContext } from '../AuthenticationProvider'
import useAxios from 'axios-hooks'
import { Response } from '../../../types/response'

interface ILoginFormProps {
  customLoginText?: string
  onLogin?: () => void
}
export const LoginForm = (props: ILoginFormProps) => {
  const { setUser } = useContext(LoggedInUserContext)
  const [{ error }, executePost] = useAxios<
    Response<ILoggedInUser>,
    { username: string; password: string }
  >(
    {
      url: '/auth/login/password',
      method: 'POST',
    },
    {
      manual: true,
    }
  )
  return (
    <>
      <Formik
        onSubmit={async (values) => {
          const userResponse = await executePost({
            data: values,
            withCredentials: true,
          })
          setUser(userResponse.data.data)
          props.onLogin && props.onLogin()
        }}
        initialValues={{ username: '', password: '' }}
      >
        <Form>
          <Title order={3} weight={600} align='center'>
            {props.customLoginText ? props.customLoginText : 'Log in'}
          </Title>
          <Stack>
            <TextInput name='username' label='username' data-autofocus />
            <TextInput type='password' name='password' label='password' />
            {error && error.response?.status === 401 && (
              <Text c='red'>Wrong Username or Password</Text>
            )}
            <Button fullWidth type='submit'>
              Log in
            </Button>
          </Stack>
        </Form>
      </Formik>
    </>
  )
}
