import { useFormikContext } from 'formik'
import useCtrlS from '../../../hooks/useCtrlS'
import { IForm } from '../types'
import { useNavigate, useParams } from 'react-router-dom'
import { PostForm } from '../commonForm/PostForm'

export const EditPostForm = () => {
  const navigate = useNavigate()
  const { uuid } = useParams()
  const { submitForm } = useFormikContext<IForm>()
  const submit = () => {
    submitForm()
  }
  useCtrlS(submit)
  return (
    <PostForm
      postUUID={uuid}
      saveAndCloseAction={() => {
        submitForm()
        navigate(`/post/${uuid}`)
      }}
    />
  )
}
