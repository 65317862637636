import { Table } from '@mantine/core'
import useAxios from 'axios-hooks'
import { Response } from '../../../types/response'
import { ITag } from '../types'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Badge } from '../../../layout/Badge'

export const ListTags = () => {
  const navigate = useNavigate()
  const [{ data: tags }] = useAxios<Response<ITag[]>>('/tag/all')

  const rows = tags?.data.map((element) => (
    <tr
      style={{ cursor: 'pointer' }}
      key={element.uuid}
      onClick={() => {
        navigate(`/tag/${element.uuid}`)
      }}
    >
      <td>
        <Badge color={element.color} key={element.uuid}>
          {element.name}
        </Badge>
      </td>
      <td>{moment(element.created_dt).format('YYYY-MM-DD HH:mm')}</td>
    </tr>
  ))
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  )
}
