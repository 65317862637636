import useAxios from 'axios-hooks'
import React, { useEffect } from 'react'
import { ReactNode, useState } from 'react'
import { Response } from '../../types/response'
export interface ILoggedInUser {
  uuid: string
  username: string
}
interface IUserContext {
  user: ILoggedInUser | undefined
  setUser(user?: ILoggedInUser): void
  getUser: () => Promise<ILoggedInUser | undefined>
}

const defaultState = {
  user: undefined,
  setUser: () => {
    console.log('User context not initialized')
  },
  getUser: () => {
    return new Promise<ILoggedInUser | undefined>(() => undefined)
  },
}

export const LoggedInUserContext =
  React.createContext<IUserContext>(defaultState)
export const AuthenticationProvider = (props: { children: ReactNode }) => {
  const [{ data }, refetchUser] = useAxios<Response<ILoggedInUser>>({
    url: '/auth/session',
  })
  const [currentUser, setCurrentUser] = useState<ILoggedInUser | undefined>(
    undefined
  )
  const getUser = () => {
    return refetchUser().then((resp) => resp.data.data)
  }
  useEffect(() => {
    if (data?.data) {
      setCurrentUser(data.data)
    }
  }, [data])
  return (
    <LoggedInUserContext.Provider
      value={{
        user: currentUser,
        setUser: setCurrentUser,
        getUser,
      }}
    >
      {props.children}
    </LoggedInUserContext.Provider>
  )
}
