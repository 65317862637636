import { useNavigate } from 'react-router-dom'
import { Title } from '@mantine/core'
import useAxios from 'axios-hooks'
import { Formik } from 'formik'
import { notifications } from '@mantine/notifications'
import { IForm } from '../types'
import { NewPostForm } from './NewPostForm'

export const NewPost = () => {
  const navigate = useNavigate()
  const [{}, executePost] = useAxios(
    {
      url: '/post/',
      method: 'POST',
    },
    {
      manual: true,
    }
  )
  return (
    <>
      <Title order={2}>New Post</Title>
      <Formik<IForm>
        initialValues={{
          title: '',
          content: '',
          tags: [],
        }}
        onSubmit={async (values) => {
          try {
            const response = await executePost({ data: values })
            navigate(`/post/${response.data.data.postUUID}`)
            notifications.show({
              title: 'Note saved',
              message: 'Your note was successfully saved',
            })
          } catch {
            notifications.show({
              title: 'Something went wrong',
              message:
                'There was some error that prevented us from saving the note',
            })
          }
        }}
      >
        <NewPostForm />
      </Formik>
    </>
  )
}
