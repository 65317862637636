import { useParams } from 'react-router-dom'
import { Title, Text } from '@mantine/core'
import useAxios from 'axios-hooks'
import { Formik } from 'formik'
import { notifications } from '@mantine/notifications'
import moment from 'moment'
import { EditPostForm } from './EditPostForm'
import { IForm, IPost } from '../types'
import { Response } from '../../../types/response'
import { ITag } from '../../tags/types'
import { useAxiosRefetching } from '../../../hooks/useAxiosRefetching'
import { LoginForm } from '../../authentication/login/LoginForm'
import axios from 'axios'

export const EditPost = () => {
  const { uuid } = useParams()
  const [{ data: postData, error: loadingPostError }, refetchPost] =
    useAxiosRefetching<Response<IPost>>('/post/' + uuid)
  const [{ data: tags }, refetchTags] = useAxiosRefetching<Response<ITag[]>>(
    `/post/${uuid}/tags`
  )
  // eslint-disable-next-line no-empty-pattern
  const [{}, executePatch] = useAxios(
    {
      url: '/post/' + uuid,
      method: 'PATCH',
    },
    {
      manual: true,
    }
  )
  if (loadingPostError?.response?.status === 401) {
    return <LoginForm customLoginText='Log in to edit this post' />
  }
  return postData ? (
    <>
      <Title order={2}>Edit {postData.data.title}</Title>
      <Text fz='xs'>
        Created at:{' '}
        {moment(postData.data.created_dt).format('YYYY-MM-DD HH:mm')}
      </Text>
      {postData.data.updated_dt && (
        <Text fz='xs'>
          Last updated at:{' '}
          {moment(postData.data.updated_dt).format('YYYY-MM-DD HH:mm')}
        </Text>
      )}
      <hr />
      <Formik<IForm>
        initialValues={{
          title: postData.data.title,
          content: postData.data.content,
          tags: tags ? tags.data.map((tag) => tag.uuid) : [],
        }}
        onSubmit={async (values) => {
          try {
            await executePatch({ data: values })
            notifications.show({
              title: 'Note saved',
              message: 'Your note was successfully saved',
            })
            refetchPost()
            refetchTags()
          } catch (error) {
            if (
              axios.isAxiosError(error) &&
              error?.response?.data.meta.message === 'NO_CHANGES'
            ) {
              notifications.show({
                title: 'No changes',
                message: 'Cannot save the note with no changes',
                color: 'yellow',
              })
            } else {
              notifications.show({
                title: 'Something went wrong',
                message:
                  'There was some error that prevented us from saving the note',
              })
            }
          }
        }}
      >
        <EditPostForm />
      </Formik>
    </>
  ) : (
    <></>
  )
}
