import { Badge, Card, Group, Input, Modal, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import useAxios from 'axios-hooks'
import { useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { TSearchResult } from './types'
import { Response } from '../types/response'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { MdContentViewer } from '../layout/MdContentViewer'

export const SearchModal = () => {
  const [opened, { open, close }] = useDisclosure(false)
  const [searchValue, setSearchValue] = useState('')
  const navigate = useNavigate()
  const [searchData, setSearchData] = useState<
    Response<TSearchResult[]> | undefined
  >(undefined)
  const [{ data }, executeSearch] = useAxios<Response<TSearchResult[]>>(
    {
      url: `/post/search/${searchValue}`,
    },
    {
      manual: true,
    }
  )

  const openModalEvent = (inputValue?: string) => {
    setSearchValue(inputValue || '')
    open()
  }

  useEffect(() => {
    if (searchValue.length > 2) {
      executeSearch()
    } else {
      setSearchData(undefined)
    }
  }, [searchValue])

  useEffect(() => {
    setSearchData(data)
  }, [data])

  const closeSearch = () => {
    setSearchValue('')
    setSearchData(undefined)
    close()
  }

  const onKeyUpSelected = (
    e: React.KeyboardEvent<HTMLDivElement>,
    noteUUID: string
  ) => {
    if (e.key === 'Enter') {
      closeSearch()
      navigate(`/post/${noteUUID}`)
    }
  }

  return (
    <>
      <Input
        onClick={() => openModalEvent()}
        onChange={(e) => openModalEvent(e.target.value)}
        placeholder='search'
        icon={<FaSearch />}
        value={searchValue}
      ></Input>
      <Modal
        size='lg'
        opened={opened}
        onClose={closeSearch}
        withCloseButton={false}
      >
        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          icon={<FaSearch />}
        ></Input>
        <Stack mt='md'>
          {!searchData && (
            <Text fw={500}>
              In order to search notes type at least 3 characters
            </Text>
          )}
          {searchData?.data.length === 0 && <Text fw={500}>No results</Text>}
          {searchData &&
            searchData.data.map((note, index) => {
              return (
                <Card
                  key={note.uuid}
                  shadow='sm'
                  padding='lg'
                  radius='md'
                  withBorder
                  onKeyUp={(e) => onKeyUpSelected(e, note.uuid)}
                  onClick={() => {
                    closeSearch()
                    navigate(`/post/${note.uuid}`)
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Group mt='md'>
                    <Text size='xl' tabIndex={0} fw={500}>
                      {note.title}
                    </Text>
                    {index === 0 && (
                      <Badge color='green' variant='light'>
                        Best Match!
                      </Badge>
                    )}
                  </Group>

                  <Text size='xs' c='dimmed' mb='md'>
                    Created:{' '}
                    {moment(note.created_dt).format('YYYY-MM-DD HH:mm')}
                  </Text>

                  <Text size='sm'>Preview:</Text>
                  <MdContentViewer content={note.content} />
                </Card>
              )
            })}
        </Stack>
      </Modal>
    </>
  )
}
