import {
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Modal,
  MultiSelect,
  MultiSelectValueProps,
  SelectItemProps,
  Text,
  rem,
} from '@mantine/core'
import { Form, useFormikContext } from 'formik'
import MDEditor from '@uiw/react-md-editor'
import TextInput from '../../../form-components/TextInput'
import useCtrlS from '../../../hooks/useCtrlS'
import { IForm } from '../types'
import { ITag } from '../../tags/types'
import { Response } from '../../../types/response'
import { forwardRef, useContext, useState } from 'react'
import { Badge } from '../../../layout/Badge'
import Color from 'color'
import { FaTimes } from 'react-icons/fa'
import { NewTag } from '../../tags/new/NewTag'
import { LoggedInUserContext } from '../../authentication/AuthenticationProvider'
import { useAxiosRefetching } from '../../../hooks/useAxiosRefetching'
import { ColorSchemeContext, TContextColorScheme } from '../../../App'
import { useOnImagePasted } from './onImagePasted'
import config from '../../../configs/config.json'

export type TPostFormProps = {
  saveAndCloseAction?: () => void
  postUUID?: string
}

const addTextAtIndex = (value: string, index: number, textToInsert: string) => {
  return value.substring(0, index) + textToInsert + value.substring(index)
}

const Value = ({
  label,
  onRemove,
  color,
  ...others
}: MultiSelectValueProps & { value: string }) => {
  return (
    <div {...others}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          cursor: 'default',
          alignItems: 'center',
          paddingLeft: theme.spacing.xs,
          borderRadius: theme.radius.sm,
        })}
      >
        <Box>
          <Badge color={color ? color : ''}>
            <Group spacing='xs'>
              {label}
              <Box
                color={Color(color).isLight() ? '#000' : '#fff'}
                onMouseDown={onRemove}
                sx={{ cursor: 'pointer' }}
              >
                <FaTimes style={{ display: 'block', margin: 'auto' }} />
              </Box>
            </Group>
          </Badge>
        </Box>

        <Box sx={{ lineHeight: 1, fontSize: rem(12) }}></Box>
      </Box>
    </div>
  )
}

const Item = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, color, ...others }, ref) => {
    return (
      <div
        style={{
          fontWeight: 500,
          color: color || '',
        }}
        ref={ref}
        {...others}
      >
        <Flex align='center'>
          <div>{label}</div>
        </Flex>
      </div>
    )
  }
)
Item.displayName = 'Item'

export const PostForm = (props: TPostFormProps) => {
  const { values, setFieldValue, submitForm } = useFormikContext<IForm>()
  const { colorScheme } = useContext<TContextColorScheme>(ColorSchemeContext)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { user } = useContext(LoggedInUserContext)
  const submit = () => {
    submitForm()
  }
  const [{ data: tags }, refetchTags] =
    useAxiosRefetching<Response<ITag[]>>('/tag/all')
  useCtrlS(submit)
  const onImagePasted = useOnImagePasted(props.postUUID)
  return (
    <>
      <Modal opened={modalOpen} onClose={() => setModalOpen(false)}>
        <NewTag
          onSuccessCallback={(addedTag: ITag) => {
            refetchTags()
            setModalOpen(false)
            setFieldValue('tags', [...values.tags, addedTag.uuid])
          }}
        />
      </Modal>

      <Form>
        <Grid>
          <Grid.Col span={12}>
            <TextInput name='title' label='Title' />
          </Grid.Col>
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col xs={9}>
                <MultiSelect
                  data={
                    tags?.data.map((tag) => ({
                      label: tag.name,
                      value: tag.uuid,
                      color: tag.color,
                      key: tag.uuid,
                    })) || []
                  }
                  disabled={!user}
                  searchable
                  label='Tags'
                  onChange={(values) => setFieldValue('tags', values)}
                  placeholder='Pick all tags that match this post'
                  value={values.tags}
                  itemComponent={Item}
                  valueComponent={Value}
                />
              </Grid.Col>
              <Grid.Col
                xs={3}
                sx={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <Button disabled={!user} onClick={() => setModalOpen(true)}>
                  Add New Tag
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>

          {!user && (
            <Grid.Col span={12}>
              <Text c='red' fw={400}>
                You need to be logged in in order to add, and manage tags
              </Text>
            </Grid.Col>
          )}
          <Grid.Col span={12} data-color-mode={colorScheme}>
            <MDEditor
              //TODO: add image pasting https://github.com/uiwjs/react-md-editor/issues/83#issuecomment-1185471844 ???
              value={values.content}
              onChange={(value) => setFieldValue('content', value)}
              autoFocus
              height={500}
              onPaste={(event) => {
                if (event.clipboardData.files.length > 0) {
                  onImagePasted(event.clipboardData, (paths) =>
                    setFieldValue(
                      'content',
                      addTextAtIndex(
                        values.content,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        event.target.selectionStart,
                        `![image](${config.BACKEND_API}/images/${paths[0]})`
                      )
                    )
                  )
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group>
              <Button type='submit' variant='filled'>
                Save
              </Button>
              {props.saveAndCloseAction && (
                <Button onClick={props.saveAndCloseAction} variant='outline'>
                  Save And Close
                </Button>
              )}
            </Group>
          </Grid.Col>
        </Grid>
      </Form>
    </>
  )
}
