import { useParams } from 'react-router-dom'
import { IPost } from '../types'
import { Response } from '../../../types/response'
import { Title, Text, Group, Button, Box, Grid } from '@mantine/core'
import moment from 'moment'
import { ITag } from '../../tags/types'
import { Badge } from '../../../layout/Badge'
import { FaTrash } from 'react-icons/fa'
import { FaShare } from 'react-icons/fa'
import { useDisclosure } from '@mantine/hooks'
import { LoginForm } from '../../authentication/login/LoginForm'
import { useAxiosRefetching } from '../../../hooks/useAxiosRefetching'
import { MdContentViewer } from '../../../layout/MdContentViewer'
import { DeleteModal } from './modals/DeleteModal'

export const ViewPost = () => {
  const { uuid } = useParams()
  if (!uuid) {
    return <></>
  }
  const [{ data, error }] = useAxiosRefetching<Response<IPost>>('/post/' + uuid)

  const [{ data: tags }] = useAxiosRefetching<Response<ITag[]>>(
    `/post/${uuid}/tags`
  )

  const [deleteModalOpened, deleteModalMethods] = useDisclosure(false)

  if (error?.response?.status === 401) {
    return <LoginForm customLoginText='Log in to see this post' />
  }

  return data ? (
    <>
      <DeleteModal
        deleteModalOpened={deleteModalOpened}
        deleteModalMethods={deleteModalMethods}
        postUUID={uuid}
        title={data.data.title}
      />
      <Grid>
        <Grid.Col span='auto'>
          <Title order={2}>{data.data.title} </Title>
        </Grid.Col>
        <Grid.Col span='content'>
          <Button variant='subtle' size='sm' onClick={() => open()}>
            <Box sx={{ textAlign: 'right' }} mr='sm'>
              <FaShare />
            </Box>
            SHARE
          </Button>
        </Grid.Col>
        <Grid.Col span='content'>
          <Button
            variant='subtle'
            color='red'
            size='sm'
            onClick={() => deleteModalMethods.open()}
          >
            <Box sx={{ textAlign: 'right' }} mr='sm'>
              <FaTrash />
            </Box>
            DELETE
          </Button>
        </Grid.Col>
      </Grid>

      <Text fz='xs'>
        Created at: {moment(data.data.created_dt).format('YYYY-MM-DD HH:mm')}
      </Text>
      {data.data.updated_dt && (
        <Text fz='xs'>
          Last updated at:{' '}
          {moment(data.data.updated_dt).format('YYYY-MM-DD HH:mm')}
        </Text>
      )}

      {tags && (
        <Text fz='xs'>
          Tags:
          <Group>
            {tags.data.map((tag) => (
              <Badge color={tag.color} key={tag.uuid}>
                {tag.name}
              </Badge>
            ))}
          </Group>
        </Text>
      )}
      <hr />
      <MdContentViewer content={data.data.content} />
    </>
  ) : (
    <></>
  )
}
