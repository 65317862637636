import useAxios from 'axios-hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { ITag } from '../types'
import { Response } from '../../../types/response'
import { Title, Text, Table } from '@mantine/core'
import moment from 'moment'
import { IPost } from '../../posts/types'

export const ViewTag = () => {
  const { uuid } = useParams()
  const navigate = useNavigate()
  const [{ data }] = useAxios<Response<ITag>>('/tag/' + uuid)
  const [{ data: postsRelatedToTag }] = useAxios<Response<IPost[]>>(
    `/tag/${uuid}/posts`
  )

  const rows = postsRelatedToTag?.data.map((post) => (
    <tr
      style={{ cursor: 'pointer' }}
      key={post.uuid}
      onClick={() => {
        navigate(`/post/${post.uuid}`)
      }}
    >
      <td>{post.title}</td>
      <td>{moment(post.created_dt).format('YYYY-MM-DD HH:mm')}</td>
      <td>
        {moment(post.updated_dt).isValid()
          ? moment(post.updated_dt).format('YYYY-MM-DD HH:mm')
          : '-'}
      </td>
    </tr>
  ))

  return data ? (
    <>
      <Title order={2}>Tag {data.data.name}</Title>
      <Text fz='xs'>
        Created at: {moment(data.data.created_dt).format('YYYY-MM-DD HH:mm')}
      </Text>
      <Text fz='xs'>Description: {data.data.description}</Text>
      <Title mt={6} order={3}>
        Posts related to this tag
      </Title>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Created</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </>
  ) : (
    <></>
  )
}
