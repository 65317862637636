import { useParams } from 'react-router-dom'
import { IPostVersion } from '../types'
import { Response } from '../../../types/response'
import { Title, Text } from '@mantine/core'
import moment from 'moment'
import { LoginForm } from '../../authentication/login/LoginForm'
import { useAxiosRefetching } from '../../../hooks/useAxiosRefetching'
import { MdContentViewer } from '../../../layout/MdContentViewer'

export const ShowVersion = () => {
  const { uuid, version } = useParams()
  const [{ data, error }] = useAxiosRefetching<Response<IPostVersion>>(
    `/post/version/${uuid}/${version}`
  )

  if (error?.response?.status === 401) {
    return <LoginForm customLoginText='Log in to see this post' />
  }
  return data ? (
    <>
      <Title order={2}>{data.data.title} </Title>

      <Text fz='md' fw={700}>
        Version: {version}
      </Text>

      <Text fz='xs'>
        Created at: {moment(data.data.created_dt).format('YYYY-MM-DD HH:mm')}
      </Text>
      <hr />
      <MdContentViewer content={data.data.content} />
    </>
  ) : (
    <></>
  )
}
