import {
  Burger,
  Group,
  MediaQuery,
  Text,
  UnstyledButton,
  Header as MantineHeader,
} from '@mantine/core'
import { NavLink } from 'react-router-dom'
import { ThemeIcon } from './ThemeIcon'
import { Login } from '../components/authentication/login/LoginModal'
import { LogoutButton } from '../components/authentication/LogoutButton'
import { RegisterModal } from '../components/authentication/registration/RegisterModal'
import { useContext } from 'react'
import { LoggedInUserContext } from '../components/authentication/AuthenticationProvider'
import { SearchModal } from './SearchModal'

interface THeader {
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
}

export const Header = ({ opened, setOpened }: THeader) => {
  const { user } = useContext(LoggedInUserContext)
  return (
    <MantineHeader sx={{ height: '100%' }} height={{ base: 50 }} p='md'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <Group position='apart' sx={{ width: '100%' }}>
          <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
            <Burger
              opened={opened}
              onClick={() => setOpened((o: boolean) => !o)}
              size='sm'
              mr='xl'
            />
          </MediaQuery>

          <NavLink to={'/'} end style={{ textDecoration: 'none' }}>
            {({ isActive }) => (
              <UnstyledButton
                sx={(theme) => ({
                  display: 'block',
                  width: '100%',
                  padding: theme.spacing.xs,
                  margin: 0,
                  textDecoration: 'none',
                  color:
                    theme.colorScheme === 'dark'
                      ? theme.colors.dark[0]
                      : theme.black,

                  '&:hover': {
                    backgroundColor:
                      theme.colorScheme === 'dark'
                        ? theme.colors.dark[6]
                        : theme.colors.gray[0],
                  },
                  backgroundColor: isActive
                    ? theme.colorScheme === 'dark'
                      ? theme.colors.dark[8]
                      : theme.colors.gray[3]
                    : 'inherit',
                })}
              >
                <Text size='sm' fw={700}>
                  HOME
                </Text>
              </UnstyledButton>
            )}
          </NavLink>
          <SearchModal />
          <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
            <Group>
              {user ? (
                <>
                  <Text size='sm' fw={700}>
                    {user.username}
                  </Text>
                  <LogoutButton />
                </>
              ) : (
                <>
                  <Login />
                  <RegisterModal />
                </>
              )}
              <ThemeIcon />
            </Group>
          </MediaQuery>
        </Group>
      </div>
    </MantineHeader>
  )
}
