import React, { Fragment, useContext } from 'react'
import {
  ThemeIcon,
  UnstyledButton,
  Group,
  Text,
  Center,
  Space,
} from '@mantine/core'
import { FaStickyNote } from 'react-icons/fa'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { LoggedInUserContext } from '../components/authentication/AuthenticationProvider'

interface IMainLinkProps {
  icon: React.ReactNode
  color: string
  label: string
  path: string
}

export const MainLinkButtonStyled = (props: {
  children: React.ReactNode
  isActive: boolean
}) => {
  return (
    <UnstyledButton
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color:
          theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
        },
        backgroundColor: props.isActive
          ? theme.colorScheme === 'dark'
            ? theme.colors.dark[8]
            : theme.colors.gray[3]
          : 'inherit',
      })}
    >
      {props.children}
    </UnstyledButton>
  )
}

export const MainLinkButton = (props: {
  isActive: boolean
  icon?: React.ReactNode
  color: string
  children: React.ReactNode
}) => {
  return (
    <MainLinkButtonStyled isActive={props.isActive}>
      <Group>
        {props.icon ? (
          <ThemeIcon color={props.color} variant='light'>
            {props.icon}
          </ThemeIcon>
        ) : (
          <Space w='xs' />
        )}
        {props.children}
      </Group>
    </MainLinkButtonStyled>
  )
}

function MainLink({ icon, color, label, path }: IMainLinkProps) {
  return (
    <NavLink to={path} end style={{ textDecoration: 'none' }}>
      {({ isActive }) => (
        <MainLinkButton isActive={isActive} color={color} icon={icon}>
          <Text size='sm'>{label}</Text>
        </MainLinkButton>
      )}
    </NavLink>
  )
}

type TNestedMainLinksProps = {
  [key: string]: IMainLinkProps[] | undefined
}

export function MainLinks() {
  const { user } = useContext(LoggedInUserContext)
  const { uuid } = useParams()
  const location = useLocation()
  const data: TNestedMainLinksProps = {
    'New Post': [
      {
        icon: <FaStickyNote />,
        color: 'yellow',
        label: 'Write a post',
        path: '/post/new',
      },
    ],
    Tags: user && [
      {
        icon: <FaStickyNote />,
        color: 'yellow',
        label: 'New tag',
        path: '/tag/new',
      },
      {
        icon: <FaStickyNote />,
        color: 'yellow',
        label: 'List tags',
        path: '/tag/list',
      },
    ],
    Post:
      /\/post\/.*/.test(location.pathname) && uuid
        ? [
            {
              icon: <FaStickyNote />,
              color: 'blue',
              label: 'View',
              path: `/post/${uuid}`,
            },
            {
              icon: <FaStickyNote />,
              color: 'blue',
              label: 'Edit',
              path: `/post/${uuid}/edit`,
            },
            {
              icon: <FaStickyNote />,
              color: 'blue',
              label: 'Versions',
              path: `/post/${uuid}/versions`,
            },
          ]
        : undefined,
  }
  // {
  //   icon: <FaStickyNote />,
  //   color: "blue",
  //   label: "Note History",
  //   path: `/post/${uuid}`,
  // },
  // {
  //   icon: <FaStickyNote />,
  //   color: "orange",
  //   label: "Privacy settings",
  //   path: `/post/${uuid}/edit`,
  // },
  // {
  //   icon: <FaStickyNote />,
  //   color: "red",
  //   label: "Delete note",
  //   path: `/post/${uuid}/edit`,
  // },

  const links = Object.entries(data).map(([key, value]) => {
    return (
      value && (
        <Fragment key={key}>
          <Center py='xs'>{key}</Center>
          {value.map((link) => (
            <MainLink {...link} key={link.label} />
          ))}
          <hr />
        </Fragment>
      )
    )
  })
  return <div>{links}</div>
}
