import { Button } from '@mantine/core'
import useAxios from 'axios-hooks'
import { LoggedInUserContext } from './AuthenticationProvider'
import { useContext } from 'react'
import { notifications } from '@mantine/notifications'

export const LogoutButton = (props: { variant?: string }) => {
  const { setUser } = useContext(LoggedInUserContext)
  const [{}, executeLogout] = useAxios(
    {
      url: '/auth/logout',
    },
    {
      manual: true,
    }
  )

  const logout = async () => {
    const logoutData = await executeLogout()
    if (logoutData.status === 200) {
      setUser(undefined)
    } else {
      notifications.show({
        color: 'red',
        title: 'Note saved',
        message: 'Your note was successfully saved',
      })
    }
  }
  return (
    <Button variant={props.variant || 'subtle'} onClick={() => logout()}>
      Logout
    </Button>
  )
}
