import { Text, Group, Button, Modal } from '@mantine/core'
import useAxios from 'axios-hooks'
import { useNavigate } from 'react-router-dom'

export interface IDeleteModalProps {
  postUUID: string
  title: string
  deleteModalOpened: boolean
  deleteModalMethods: { open: () => void; close: () => void }
}

export const DeleteModal = ({
  postUUID,
  title,
  deleteModalOpened,
  deleteModalMethods,
}: IDeleteModalProps) => {
  const navigate = useNavigate()
  const [{ loading: deleting }, removePost] = useAxios(
    {
      method: 'DELETE',
      url: '/post/' + postUUID,
    },
    {
      manual: true,
    }
  )

  return (
    <Modal
      opened={deleteModalOpened}
      onClose={deleteModalMethods.close}
      title='Are you sure?'
    >
      <Text>
        Are you sure you want to delete
        <Text span fw={800}>
          {' '}
          {title}
        </Text>
        ?
        <Text>
          This action is irreversable! Make sure this is the post you want to
          remove!
        </Text>
      </Text>
      <Group position='right'>
        <Button variant='outline' onClick={() => deleteModalMethods.close()}>
          Cancel
        </Button>
        <Button
          loading={deleting}
          variant='filled'
          onClick={async () => {
            await removePost()
            deleteModalMethods.close()
            navigate('/')
          }}
        >
          Remove
        </Button>
      </Group>
    </Modal>
  )
}
