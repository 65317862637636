import { Button, Stack, Title } from '@mantine/core'
import TextInput from '../../../form-components/TextInput'
import { Form, Formik } from 'formik'
import useAxios from 'axios-hooks'
import { Response } from '../../../types/response'
import { AxiosError } from 'axios'
import { handleValidationErrors } from '../../../helpers/form'

interface ILoginFormProps {
  onRegister?: () => void
}
export const RegisterForm = (props: ILoginFormProps) => {
  const [{}, executePost] = useAxios<
    Response<Record<string, never>>,
    { username: string; password: string; passwordConfirm: string }
  >(
    {
      url: '/auth/register/password',
      method: 'POST',
    },
    {
      manual: true,
    }
  )
  return (
    <>
      <Formik
        onSubmit={async (values, { setErrors }) => {
          await executePost({
            data: values,
            withCredentials: true,
          })
            .then(() => props.onRegister && props.onRegister())
            .catch((err: Error | AxiosError) =>
              handleValidationErrors(err, setErrors)
            )
        }}
        initialValues={{ username: '', password: '', passwordConfirm: '' }}
      >
        <Form>
          <Title order={3} weight={600} align='center'>
            Register
          </Title>
          <Stack>
            <TextInput name='username' label='username' data-autofocus />
            <TextInput type='password' name='password' label='password' />
            <TextInput
              type='password'
              name='passwordConfirm'
              label='password confirmation'
            />
            <Button fullWidth type='submit'>
              Register
            </Button>
          </Stack>
        </Form>
      </Formik>
    </>
  )
}
