import { Routes, Route } from 'react-router-dom'
import { ColorScheme, Container, MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { EditPost } from './components/posts/edit/EditPost'
import { NewPost } from './components/posts/new/NewPost'
import { ViewPost } from './components/posts/view/ViewPost'
import { ShowLastPosts } from './components/posts/latest/ShowLastPosts'
import { Shell } from './layout/Shell'
import { createContext } from 'react'
import { useLocalStorage } from '@mantine/hooks'
import { NewTag } from './components/tags/new/NewTag'
import { ViewTag } from './components/tags/view/ViewTag'
import { ListTags } from './components/tags/list/ListTags'
import { configure } from 'axios-hooks'
import axios from 'axios'
import config from './configs/config.json'
import { AuthenticationProvider } from './components/authentication/AuthenticationProvider'
import { ListPostVersions } from './components/posts/versions/ListPostVersions'
import { ShowVersion } from './components/posts/versions/ShowVersion'

export type TContextColorScheme = {
  colorScheme: ColorScheme
  toggleColorScheme?: () => void
}
export const ColorSchemeContext = createContext<TContextColorScheme>({
  colorScheme: 'dark',
})

export function App() {
  const axiosConfig = axios.create({
    baseURL: config.BACKEND_API,
    withCredentials: true,
  })
  configure({ axios: axiosConfig, defaultOptions: { useCache: false } })
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'dark',
    getInitialValueInEffect: true,
  })
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  return (
    <AuthenticationProvider>
      <ColorSchemeContext.Provider value={{ colorScheme, toggleColorScheme }}>
        <MantineProvider
          theme={{ colorScheme: colorScheme }}
          withGlobalStyles
          withNormalizeCSS
        >
          <Notifications />

          <Container size='lg' style={{ height: '100%' }}>
            <Routes>
              <Route element={<Shell />}>
                <Route path='post/:uuid/edit' element={<EditPost />} />
                <Route path='post/:uuid' element={<ViewPost />} />
                <Route path='post/new' element={<NewPost />} />
                <Route
                  path='post/:uuid/versions'
                  element={<ListPostVersions />}
                />
                <Route path='post/:uuid/:version' element={<ShowVersion />} />
                <Route path='tag/:uuid' element={<ViewTag />} />
                <Route
                  path='tag/new'
                  element={<NewTag navigateToViewAfterSuccess />}
                />
                <Route path='tag/list' element={<ListTags />} />
                <Route path='/' element={<ShowLastPosts />} />
              </Route>
            </Routes>
          </Container>
        </MantineProvider>
      </ColorSchemeContext.Provider>
    </AuthenticationProvider>
  )
}

export default App
