import { AxiosRequestConfig } from 'axios'
import useAxios, { Options, UseAxiosResult } from 'axios-hooks'
import { useContext, useEffect } from 'react'
import { LoggedInUserContext } from '../components/authentication/AuthenticationProvider'

export function useAxiosRefetching<
  TResponse = unknown,
  TBody = unknown,
  TError = unknown
>(
  config: AxiosRequestConfig<TBody> | string,
  options?: Options
): UseAxiosResult<TResponse, TBody, TError> {
  const { user } = useContext(LoggedInUserContext)
  const [data, refetch, manualCancel] = useAxios(config, options)
  useEffect(() => {
    if (!data.loading) {
      refetch()
    }
  }, [user])
  return [data, refetch, manualCancel]
}
