import { AppShell, Box, Group, MediaQuery, Navbar, Text } from '@mantine/core'
import { MainLinkButton, MainLinks } from './MainLinks'
import { Outlet } from 'react-router-dom'
import { useContext, useState } from 'react'
import { ThemeIcon } from './ThemeIcon'
import { Login } from '../components/authentication/login/LoginModal'
import { LoggedInUserContext } from '../components/authentication/AuthenticationProvider'
import { LogoutButton } from '../components/authentication/LogoutButton'
import { RegisterModal } from '../components/authentication/registration/RegisterModal'
import { FaUserCircle } from 'react-icons/fa'
import { Header } from './Header'

export const Shell = () => {
  const [opened, setOpened] = useState(false)
  const { user } = useContext(LoggedInUserContext)
  return (
    <AppShell
      navbarOffsetBreakpoint='sm'
      padding='md'
      fixed
      navbar={
        <Navbar
          hiddenBreakpoint='sm'
          hidden={!opened}
          width={{ sm: 200, lg: 300 }}
        >
          <Navbar.Section grow mt='xs' onClick={() => setOpened(false)}>
            <MainLinks />
            <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
              <Group dir='column' spacing={0}>
                {user ? (
                  <>
                    <MainLinkButton
                      icon={<FaUserCircle />}
                      isActive={false}
                      color={'#fff'}
                    >
                      Logged in as
                      <Text fw={800}>{user.username}</Text>
                    </MainLinkButton>
                    <MainLinkButton isActive={false} color={'#fff'}>
                      <LogoutButton variant='default' />
                    </MainLinkButton>
                  </>
                ) : (
                  <>
                    <MainLinkButton isActive={false} color={'#fff'}>
                      <Login />
                    </MainLinkButton>
                    <MainLinkButton isActive={false} color={'#fff'}>
                      <RegisterModal />
                    </MainLinkButton>
                  </>
                )}
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ThemeIcon />
                </Box>
              </Group>
            </MediaQuery>
          </Navbar.Section>
        </Navbar>
      }
      header={<Header opened={opened} setOpened={setOpened} />}
    >
      <Outlet />
    </AppShell>
  )
}
