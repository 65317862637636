import useAxios from 'axios-hooks'
import { IUploadedImagesPaths } from './types'
import { Response } from '../../../types/response'
import { notifications } from '@mantine/notifications'

export const useOnImagePasted = (postUUID?: string) => {
  const [, executePost] = useAxios<Response<IUploadedImagesPaths>>(
    {
      url: `/images/${postUUID}`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    {
      manual: true,
    }
  )
  const allowedTypes = ['image/jpeg', 'image/png', 'image/gif']
  return (
    dataTransfer: DataTransfer,
    callbackFn: (paths: string[]) => void
  ) => {
    if (!postUUID) {
      notifications.show({
        color: 'red',
        title: 'Unable to upload images to a new post',
        message: 'To be able to uplad images please save the post',
      })
    } else {
      const formData = new FormData()
      Array.from(dataTransfer.files).forEach((file) => {
        if (allowedTypes.includes(file.type)) {
          formData.append('images', file)
        } else {
          notifications.show({
            color: 'red',
            title: 'Unable to upload',
            message: `File ${file.name} is not of a image type. It will not be uploaded`,
          })
        }
      })
      if (formData.getAll('images').length > 0) {
        executePost({ data: formData }).then((response) =>
          callbackFn(response.data.data)
        )
      }
    }
  }
}
