import { useFormikContext } from 'formik'
import useCtrlS from '../../../hooks/useCtrlS'
import { IForm } from '../types'
import { PostForm } from '../commonForm/PostForm'

export const NewPostForm = () => {
  const { submitForm } = useFormikContext<IForm>()
  const submit = () => {
    submitForm()
  }
  useCtrlS(submit)
  return <PostForm />
}
