import { Card, Stack, Text } from '@mantine/core'
import { IPost } from '../types'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export const CardStack = ({ posts }: { posts: IPost[] }) => {
  const navigate = useNavigate()
  return (
    <Stack>
      {posts.map((post) => (
        <Card
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/post/${post.uuid}`)}
          key={post.uuid}
          shadow='sm'
          padding='lg'
          radius='md'
          withBorder
        >
          <Text weight={500} fz='lg'>
            {post.title}
          </Text>
          <Text fz='sm'>
            Created at: {moment(post.created_dt).format('YYYY-MM-DD HH:mm')}
          </Text>
          {post.updated_dt && (
            <Text fz='sm'>
              Last updated at:{' '}
              {moment(post.updated_dt).format('YYYY-MM-DD HH:mm')}
            </Text>
          )}
        </Card>
      ))}
    </Stack>
  )
}
