import { Button, ColorInput, Grid } from '@mantine/core'
import { Form, useFormikContext } from 'formik'
import TextInput from '../../../form-components/TextInput'
import useCtrlS from '../../../hooks/useCtrlS'
import { IForm } from '../types'
import TextArea from '../../../form-components/TextArea'

export const NewTagForm = () => {
  const { submitForm, values, setFieldValue } = useFormikContext<IForm>()
  const submit = () => {
    submitForm()
  }
  useCtrlS(submit)
  return (
    <Form>
      <Grid>
        <Grid.Col span={12}>
          <TextInput name='name' label='Name' />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextArea name='description' label='Description' />
        </Grid.Col>
        <Grid.Col span={12}>
          <ColorInput
          placeholder="Pick color" label="Tag color background" 
            value={values.color}
            onChange={(value) => setFieldValue('color', value)}
            format='hex'
            swatches={[
              '#25262b',
              '#868e96',
              '#fa5252',
              '#e64980',
              '#be4bdb',
              '#7950f2',
              '#4c6ef5',
              '#228be6',
              '#15aabf',
              '#12b886',
              '#40c057',
              '#82c91e',
              '#fab005',
              '#fd7e14',
            ]}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Button type='submit'>Save</Button>
        </Grid.Col>
      </Grid>
    </Form>
  )
}
