import { useContext } from 'react'
import { ColorSchemeContext, TContextColorScheme } from '../App'
import MarkdownPreview from '@uiw/react-markdown-preview'

export const MdContentViewer = ({ content }: { content: string }) => {
  const { colorScheme } = useContext<TContextColorScheme>(ColorSchemeContext)
  return (
    <div data-color-mode={colorScheme}>
      <MarkdownPreview style={{ padding: '20px' }} source={content} />
    </div>
  )
}
